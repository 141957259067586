<template>
  <div class="mine_wrap" id="box">
    <div class="mine_bg">
      <van-image class="mine_bg_li" :src="bg"></van-image>
      <div class="mine_top">
        <div>
          <van-image class="mine_top_img" :src="photo"></van-image>
        </div>
        <div class="mine_top_namb">
          <div class="mine_top_name" v-if="myInfo.username">
            <span class="mine_top_namb_right">{{ myInfo.username }}</span
            ><span>已实名</span>
          </div>
          <!-- <div class="mine_top_name">
            <span class="mine_top_namb_right">段位呀</span><span>已实名</span>
          </div> -->
          <div class="mine_top_tel">{{ mobile }}</div>
        </div>
      </div>
      <div class="mine_center">
        <div @click="toMySocial">
          <div class="mine_center_li">
            <van-image
              class="mine_top_li_img"
              :src="socialImg"
              alt=""
            ></van-image>
          </div>
          <div>我的社保</div>
        </div>
        <div @click="toMySalary">
          <div class="mine_center_li">
            <van-image
              class="mine_top_li_img"
              :src="salaryImg"
              alt=""
            ></van-image>
          </div>
          <div>我的薪资</div>
        </div>
        <div @click="toMessage">
          <div class="mine_center_li">
            <van-image
              class="mine_top_li_img"
              :src="messageImg"
              alt=""
            ></van-image>
          </div>
          <div>我的消息</div>
        </div>
      </div>
    </div>
    <div class="mine_content">
      <div>
        <div class="mine_content_lsit" @click="toPreviousInfo">
          <div class="mine_content_list_left">
            <span
              class="iconfont icon-shimingrenzheng mine_content_list_left_icon"
            ></span
            >实名认证
          </div>
          <div class="mine_content_list_right">
            <span v-if="myInfo.shiming_b === 1">已实名</span
            ><span v-else-if="myInfo.shiming_b === 0">审核中</span
            ><span v-else>待审核</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="mine_content_lsit" @click="toQianyueList">
          <div class="mine_content_list_left">
            <span
              class="iconfont icon-qianyue mine_content_list_left_icon"
            ></span
            >我的合同
          </div>
          <div class="mine_content_list_right">
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="mine_content_lsit" @click="toFankui">
          <div class="mine_content_list_left">
            <span
              class="iconfont icon-fankui mine_content_list_left_icon"
            ></span
            >我的反馈
          </div>
          <div class="mine_content_list_right">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="logout_btn" @click="toLogin">
      <van-button size="large">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import "../assets/css/iconfont.css";
export default {
  name: "Home",
  data() {
    return {
      photo: require("../assets/images/logo.png"),
      bg: require("../assets/images/bg1.png"),
      socialImg: require("../assets/images/u109.svg"),
      salaryImg: require("../assets/images/u112.svg"),
      messageImg: require("../assets/images/u122.svg"),
      pid: "",
      mobile: "",
      myInfo: {},
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    this.pid = pid;
    document
      .querySelector("body")
      .setAttribute("style", "background-color:rgba(239, 242, 247, 1)");
    let mobile = localStorage.getItem("mobile");
    // let mobile = "17521242348";
    this.mobile = mobile;
    this.getMyInfo();
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {
    toMySocial() {
      this.$router.push({ name: "mySocial" });
    },
    toMySalary() {
      this.$router.push({ name: "mySalary" });
    },
    toMessage() {
      this.$router.push({ name: "message" });
    },
    //获取个人信息
    getMyInfo() {
      this.$axios
        .get(
          "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
            this.pid +
            '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","status":"userstatus","data_status":"data_statusb","shiming":"shiming_b"}'
        )
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            let isArray = returnData instanceof Array;
            if (!isArray) {
              //data_statusb=1代表审核通过，data_statusb=2代表待审核，
              this.myInfo = returnData;
              // this.getpersonStatus(returnData);
            }
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    //我的信息
    toPreviousInfo() {
      this.$router.push({ name: "previousInfo" });
    },
    //签约列表
    toQianyueList() {
      this.$router.push({ name: "qianyueList" });
    },
    //反馈列表
    toFankui() {
      this.$router.push({ name: "fankui" });
    },
    //前往登录页面
    toLogin() {
      //退出登录时，清除缓存
      localStorage.removeItem("pid");
      localStorage.removeItem("card_code");
      localStorage.removeItem("mobile");
      localStorage.removeItem("realname");
      localStorage.removeItem("access_token");
      localStorage.removeItem("id");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style scoped>
.font_hetong {
  font-size: 1rem;
}
.logout_btn {
  width: calc(100% - 1.2rem);
  margin: 0 auto;
  margin-top: 0.8rem;
  border-radius: 0.5rem;
  height: 3rem;
}
.icon_font {
  font-size: 1rem;
}
.mine_wrap {
  background-color: rgba(239, 242, 247, 1);
}
.mine_bg {
  position: relative;
}
.mine_bg_li {
  width: 100%;
  height: 12.25rem;
}
.mine_top {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 1.875rem;
  left: 1.25rem;
  display: flex;
  justify-content: space-between;
}
.mine_top_img {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  overflow: hidden;
}
.mine_lists {
  /* background-color: #fff; */
  width: calc(100%);
  margin: 0 auto;
  font-size: 1rem;
  color: #555;
}
.mine_list {
  background-color: #fff;
  /* width: calc(100% - 0.4rem); */
  margin: 0 auto;
  border-bottom: 0.0625rem solid #eee;
  line-height: 3.25rem;
  height: 3.25rem;
  display: flex;
  justify-content: space-between;
}
.mine_list_row {
  margin-right: 2rem;
}
.mine_list_left {
  margin-left: 1rem;
}
.mine_list_con {
  margin-left: 0.5rem;
}
.mine_top_name {
  /* width:75%; */
  overflow: hidden; /* 超出元素宽度范围时候不使用滚动条 */
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
.mine_center {
  position: absolute;
  bottom: -2.5rem;
  left: 0.9375rem;
  border: 0.0625rem solid #eee;
  width: calc(100% - 1.875rem);
  height: 6.5rem;
  background-color: #fff;
  border-radius: 0.3125rem;
  box-shadow: 0.3125rem 0.3125rem 0.3125rem rgb(0 0 0 / 21%);
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
.mine_center_li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0;
  box-sizing: border-box;
}
.mine_top_li_img {
  width: 2.8125rem;
  height: 2.8125rem;
}
.mine_top_namb {
  color: #fff;
  text-align: left;
  margin-left: 1.25rem;
}

.mine_top_name {
  font-size: 1.3125rem;
}

.mine_top_namb_right {
  margin-right: 0.3125rem;
}

.mine_center_li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0;
  box-sizing: border-box;
}

.mine_top_tel {
  line-height: 1.5625rem;
  font-size: 0.875rem;
}

.mine_content {
  border-top: 0.0625rem solid #ddd;
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 3.75rem;
  background-color: #fff;
  border: 0.0625rem solid #ddd;
  border-radius: 0.3125rem;
}

.mine_content_lsit {
  display: flex;
  justify-content: space-between;
  height: 2.625rem;
  line-height: 2.625rem;
  border-bottom: 0.0625rem solid rgba(242, 242, 242, 1);
  color: rgba(51, 51, 51, 0.847058823529412);
}

.mine_content_list_left {
  padding-left: 0.625rem;
  box-sizing: border-box;
}

.mine_content_list_left_icon {
  color: #0079fe;
  margin-right: 0.625rem;
  height: 2.625rem;
  line-height: 2.625rem;
}

.mine_content_list_right {
  padding-right: 1.25rem;
  box-sizing: border-box;
}
.logout_btn{
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.625rem;
  border-radius: 0.3125rem;
}

</style>
<style>
.van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}
.logout_btn .van-button {
  border-radius: 0.625rem !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 1rem !important;
}
</style>